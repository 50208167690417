<template>
  <div class="device-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator-iot/device/row-statistics/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="$auth('遥监设备新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>

      <el-button
        v-if="$auth('遥监设备导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>

      <el-button
        v-if="$auth('遥监设备绑定导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="bindVisible=!bindVisible">
        电梯设备绑定导入
      </el-button>

      <el-button
        v-if="$auth('遥监设备批量修改设备状态')"
        slot="toolbar"
        style="margin-left: 4px"
        type="primary"
        size="mini"
        @click="$refs.batch.open(selectList)">
        {{$l("common.add", "批量修改设备状态")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="设备名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="设备型号">
            <vm-dict-select v-model="filter.model" type="iotDeviceModel"></vm-dict-select>
          </vm-search>
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="电梯注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="电梯出厂编号">
            <el-input v-model.trim="filter.factoryCode" clearable></el-input>
          </vm-search>
          <vm-search label="ICCID">
            <el-input v-model.trim="filter.iccidCode" clearable></el-input>
          </vm-search>
          <vm-search label="设备状态">
            <vm-dict-select v-model="filter.status" type="iotDeviceStatus"></vm-dict-select>
          </vm-search>
          <vm-search label="联网协议来源">
            <vm-dict-select v-model="filter.iotProtocolSource" type="iotProtocolSource"></vm-dict-select>
          </vm-search>
          <vm-search label="绑定电梯数量">
            <el-input-number v-model="filter.bindElevatorCount" style="width: 193px" :min="0"></el-input-number>
          </vm-search>
          <vm-search label="是否在线">
            <el-select v-model="filter.online" style="width: 193px">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="是否主板连接异常">
            <el-select v-model="filter.boardConnectionException" style="width: 193px">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>
      <div slot="tree" :style="{width:width+'px'}" class="treeBox">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.ports"
            label-position="left"
            class="demo-table-expand">
            <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

            <el-table-column prop="name" label="端口号" align="center">
            </el-table-column>
            <el-table-column prop="regCode" label="注册代码" align="center">
            </el-table-column>
            <el-table-column prop="lastHeartbeatTime" label="上次心跳时间" align="center">
            </el-table-column>
            <el-table-column prop="online" label="是否在线" align="center">
              <template slot-scope="scope">
                {{scope.row.online?"是":"否"}}
              </template>
            </el-table-column>
            <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
              <template slot-scope="scope">
                <el-button v-if="$auth('设备端口删除')" type="danger" @click="deletePort(scope.row)">{{$l("common.delete", "删除")}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="name" label="设备名称/编号" align="center"></el-table-column>
      <el-table-column prop="iccidCode" label="ICCID" align="center"></el-table-column>
      <el-table-column prop="iotProtocolSourceDesc" label="物联网协议来源" align="center"></el-table-column>
      <el-table-column prop="iotProtocolVersion" label="协议版本" align="center"></el-table-column>
      <el-table-column prop="iotSupportBoardTypeDesc" label="支持主板" align="center"></el-table-column>
      <el-table-column prop="modelDesc" label="设备型号" align="center"></el-table-column>
      <el-table-column prop="boardConnectionException" label="是否主板连接异常" align="center">
        <template slot-scope="scope">
          {{scope.row.boardConnectionException?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘楼宇" align="center">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}{{scope.row.realEstateName?'-':''}}{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorQuantity" label="绑定电梯数量" align="center"></el-table-column>

      <el-table-column label="在线状态" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.online?'success':'info'">{{scope.row.online?'在线':'离线'}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="设备状态" align="center" prop="statusDesc">
      </el-table-column>
      <el-table-column

        prop="lastHeartbeatTime"
        align="center">
        <template slot="header" slot-scope="">
          上次心跳时间 <el-button type="text" icon="el-icon-sort" @click="sort"></el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('遥监设备编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('遥监设备详情')" type="primary" @click="$refs.detail.open(scope.row.id)">{{$l("common.edit", "详情")}}</el-button>
          <el-button v-if="$auth('设备端口新增')" type="primary" @click="$refs.addPort.open(scope.row.id)">{{$l("common.edit", "新增端口")}}</el-button>
          <el-button v-if="$auth('遥监设备删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <detail ref="detail" @save-success="getList(-1)"></detail>
    <add-port ref="addPort" @save-success="getList(-1)"></add-port>
    <batch ref="batch" @save-success="getList(-1)"></batch>
    <status-log ref="statusLog"></status-log>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/iotDevice.xlsx"
        action="elevator-iot/device/import"
        name="遥监设备导入"
        download-name="遥监设备.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
    <el-dialog :visible.sync="bindVisible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/iotDeviceElevator.xlsx"
        action="elevator-iot/device/import-bind"
        name="遥监设备导入"
        download-name="遥监设备电梯绑定.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  import StatusLog from "./StatusLog.vue";
  import {getWidth} from "@/util";
  import detail from "@/views/device/detail";
  import batch from "@/views/device/batch";
  import AddPort from "./AddPort.vue";
  import ElevatorDetail from "@/views/elevator/ElevatorDetail";

  export default {
    components: {EditPage, StatusLog,detail,batch,AddPort,ElevatorDetail},
    props:{
      query:Object,
    },
    data() {
      return {
        loading:false,
        filter: {
          no: "",
          iccid: "",
          source: "",
          name:"",
          realEstateName:"",
          companyId: "",
        },
        data:[],
        width:200,
        selectList:[],
        visible:false,
        bindVisible:false,
        asc:0,
      };
    },
    watch:{
      query:{
        handler(params) {
          console.log(params,139);
          for (let [key,value] of Object.entries(params)) {
            if (key==="iotCode") {
              this.filter.name=value;
              this.getList(1);
            }
          }
        },
        deep:true,
        // immediate:true,
      },
    },
    mounted() {
      const os = localStorage.getItem("os");
      if (os==="new") {
        this.filter.name = sessionStorage.getItem("iotCode");
        sessionStorage.removeItem("iotCode");
      }else {
        this.filter.name=this.$route.query.iotCode;

      }
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      handleSelectionChange(val) {
        this.selectList = val;
      },
      sort() {
        console.log(230);
        this.filter.orderColumnName="lastHeartbeatTime";
        this.filter.asc = this.asc;
        this.asc = this.asc?0:1;
        this.getList();
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
        this.bindVisible=false;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`elevator-iot/device/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.code + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      deletePort(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`elevator-iot/iot-device-port/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.code + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      statusLog(row) {
        console.log(row.no);
        this.$refs.statusLog.open(row.no);
      },
      showDrawer() {
        this.$refs.showRight.open();
      },
      closeDrawer() {
        this.$refs.showRight.close();
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
          console.log(getWidth(data),this.width,211);

        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  min-width: 150px;
  max-height: 750px;
  overflow-y: auto;
}
.size{
  font-size: small;
}
</style>
